export const content = {

    en: {
        deactivate: 'Deactivate',
        reset: 'Reset'
    },
    
    pl: {
        deactivate: 'Dezaktywuj',
        reset: 'Reset'
    }

}