export const content = {

    en: {
        copyLink: 'Copy link',
        shareWith: 'Share with:',
        mailSubject: 'RunCalc: my running plan',
    },
    
    pl: {
        copyLink: 'Skopiuj link',
        shareWith: 'Udostępnij przez',
        mailSubject: 'RunCalc: mój plan na bieg',
    }

}