export const content = {

    en: {
        summary: 'Summary',
        segmentsAmount: 'Segments amount',
    },
    
    pl: {
        summary: 'Podsumowanie',
        segmentsAmount: 'Ilość odcinków',
    }

}