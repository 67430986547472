export const content = {

    en: {
        heading: 'Segment no',
        removeSegment: 'Remove',
        reset: 'Reset'
    },
    
    pl: {
        heading: 'Segment nr',
        removeSegment: 'Usuń',
        reset: 'Reset'
    }

}