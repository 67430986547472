export const content = {

    en: {
        addSegment: 'Add segment',
    },
    
    pl: {
        addSegment: 'Dodaj odcinek',
    }

}