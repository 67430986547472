export const content = {

    en: {
        standardVal: 'Standard values',
    },
    
    pl: {
        standardVal: 'Standardowe wartości',
    }

}